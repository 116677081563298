<template>
  <div class="index">
    <div class="index_first">
      <div style="display: flex; justify-content: center">
        <div style="width: 900px;">
          <el-carousel indicator-position="outside" height="435px" ref="carousel">
            <el-carousel-item v-for="(item, index) in bannerData" :key="index" @click.native="linkTo(item.jumpUrl)">
              <img :src="`${item.imageUrl}`" width="900" height="435px" style="cursor: pointer" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="width: 450px; margin-left: 5px">
          <img
            :src="`${bannerDataRight.top.url}`"
            @click="imgTolink(bannerDataRight.top.link)"
            height="215"
            width="475"
            style="cursor: pointer"
            alt="" />
          <img
            :src="`${bannerDataRight.bottom.url}`"
            @click="imgTolink(bannerDataRight.bottom.link)"
            height="215"
            width="475"
            style="cursor: pointer"
            alt="" />
        </div>
        <!-- 公告 -->
        <div style="width: 200px;margin-left: 32px;border: 2px solid rgb(33, 141, 160);height: 435px;background-color: #fff;">
          <div>
              <div style="text-align: left;  color: #fff;background-color: rgb(33, 141, 160);padding: 18px;font-weight: bold;">
                <span style="font-weight:bold">{{ $t("Bulletin Board index") }}</span>
                <a @click="JumpToAllNotice" >
                  <span style="float:right;font-size: 12px;">{{ $t("MORE") }}<i class="el-icon-arrow-right"></i></span>
                </a>
              </div>
              <div class="noticeStyle" v-for="(item,index) in noticeData" :key="index" @click="JumpToNoticeDetail(item)" style="padding: 10px;margin-top:0px;">
                <span style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;" v-if="lang=='zh-CN'">{{ item.shot_title }}</span>
                <span style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;" v-else >{{ item.shot_title_en }}</span>            
              </div>
              <div style="display: flow-root;margin-top: 50px;">
                  <span class="noticeIconStyle" v-for="(itemIcon,index) in noticeIconData" :key="index" @click="JumpToNoticeDetail(itemIcon)" style="padding: 10px;margin-top:0px;">
                    <img style="width: 75px;height: 75px;" :src="itemIcon.icon_img_url" class="image">
                  </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index_second">
      <div class="index_second_left">
        <div class="index_second_title">
          <div style="width: 30px; height: 30px; margin-left: 15px">
            <img src="~@/assets/images/icon_recommended.png" />
          </div>
          <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
            {{ $t("POPULAR PRODUCTS") }}
          </p>
        </div>
        <div>
          <img
            :src="`http://hwimg.xmvogue.com/thumb/${hotGoodsData.firtData.item_no}.jpg?x-oss-process=style/440`"
            style="width: 260px; height: 260px; margin: 0px 20px 0px 20px"
            alt=""
          />
        </div>
        <div class="index_second_bottom">
          <div class="index_second_bottom_content" style="width: 260px; height: 36px">
            <p style="font-size: 14px; color: #666666">
              {{ hotGoodsData.firtData.item_en | cutstr }}
            </p>
          </div>
          <div style="width: 260px; height: 40px">
            <div class="index_second_bottom_content">
              <div style="font-size: 14px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
              <div style="font-size: 14px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                }}{{ hotGoodsData.firtData.base_price }}
              </div>
            </div>
            <div class="index_second_bottom_content">
              <div style="font-size: 14px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
              <div style="font-size: 14px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                }}{{ hotGoodsData.firtData.sale_price }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_second_right">
        <template v-for="(item, index) in hotGoodsData.listData">
          <div class="index_second_right_content" :key="index" @click="goToUrl(item.item_no)">
            <div style="cursor: pointer">
              <img
                :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
                style="width: 150px; height: 150px; margin: 10px 14.5px 0px 14.5px"
                alt=""
              />
            </div>
            <div class="index_second_right_bottom">
              <div class="index_second_bottom_content">
                <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                  <p style="font-size: 14px; color: #666666">
                    {{ item.item_en | cutstr(15) }}
                  </p>
                </el-tooltip>
              </div>
              <div style="width: 160px; height: 32px">
                <div class="index_second_bottom_content">
                  <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                  <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                    }}{{ item.base_price }}
                  </div>
                </div>
                <div class="index_second_bottom_content">
                  <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                  <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                    }}{{ item.sale_price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="index_third" v-show="homeGoodsData.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_Household.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          <!-- 家具用品 -->
          {{ $t("HOUSEHOLD DEPARTMENT") }}
        </p>
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=21" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_Household.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in homeGoodsData" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="HEALTH_AND_BEAUTY.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_beauty.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("HEALTH AND BEAUTY") }}
          <!-- 健康美容 -->
        </p>
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=22" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_beauty.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>

      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in HEALTH_AND_BEAUTY" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="ACCESSORIES_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_accessories.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("ACCESSORIES") }}
        </p>
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=23" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_accessories.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in ACCESSORIES_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="STATIONERY_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_stationery.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("STATIONERY") }}
        </p>
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=24" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_stationery.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in STATIONERY_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="SEASONAL_PRODUCTS_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_Seasonal_products.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("SEASONAL PRODUCTS") }}
        </p>
        <!--        季节性-->
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=25" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_Seasonal_products.png"
               style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in SEASONAL_PRODUCTS_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="BACKPACK_JEWELRY_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_Backpack.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">BACKPACK JEWELRY</p>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_Backpack.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in BACKPACK_JEWELRY_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="TOY_AND_DOLL_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_toy.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("TOY AND DOLL") }}
        </p>
        <!--        玩具-->
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=27" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_toy.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in TOY_AND_DOLL_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="index_third" v-show="DIGITAL_ACCESSORIES_Data.length > 0">
      <div class="index_third_header">
        <div>
          <img src="~@/assets/images/icon_Digital.png" style="width: 30px; height: 30px" />
        </div>
        <p style="font-size: 20px; color: #333333; font-weight: bold; margin-left: 10px">
          {{ $t("DIGITAL ACCESSORIES") }}
        </p>
        <!--        数码-->
        <div style="margin-left: auto; padding-right: 25px; z-index: 99">
          <a href="#/web/allgoods?cls_no=29" target="_blank">查看更多</a>
        </div>
      </div>
      <div class="index_third_middle">
        <div>
          <img src="~@/assets/images/banner_Digital.png" style="width: 1160px; height: 300px; margin-left: 20px" />
        </div>
      </div>
      <div class="index_third_bottom">
        <div class="index_third_bottom_content" v-for="(item, index) in DIGITAL_ACCESSORIES_Data" :key="index">
          <div @click="goToUrl(item.item_no)" style="cursor: pointer">
            <img
              :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
              style="width: 220px; height: 220px; margin: 10px 0px 0px 8px"
            />
          </div>
          <div class="index_third_bottom_mark" @click="goToUrl(item.item_no)">
            <div class="index_second_bottom_content" style="width: 220px; height: 60px">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="font-size: 14px; color: #666666">
                  {{ item.item_en | cutstr(40) }}
                </p>
              </el-tooltip>
            </div>
            <div style="width: 160px; height: 32px">
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("PURCHASE PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.base_price }}
                </div>
              </div>
              <div class="index_second_bottom_content">
                <div style="font-size: 12px; color: #333333; font-weight: bold">{{ $t("RETAIL PRICE") }}:</div>
                <div style="font-size: 12px; color: #e1251b; font-weight: bold">{{ $t("Price symbol")
                  }}{{ item.sale_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </div>
</template>
<script>
import common from "@/common/mixins/common.js";
import { GetTypeGoods, GoodsGetHot } from "@/api/goods/goods";
import banner from "@/api/web/home/banner";
import notice from "@/api/web/home/notice";


export default {
  mixins: [common],
  data() {
    return {
      //热门产品
      hotGoodsData: {
        firtData: {},
        listData: [],
      },
      DIGITAL_ACCESSORIES_Data: [], //数码产品
      TOY_AND_DOLL_Data: [], //公仔
      BACKPACK_JEWELRY_Data: [], //包包
      SEASONAL_PRODUCTS_Data: [], //季节性产品
      STATIONERY_Data: [], //文具
      ACCESSORIES_Data: [], //饰品
      HEALTH_AND_BEAUTY: [], //健康美容
      homeGoodsData: [], //家居用品,生活工具
      bannerData: [
        // {
        //   url: require("@/assets/images/presell/04/3.jpg"),
        //   link: "/web/presellProduct",
        // },
        {
          imageUrl: require("@/assets/images/banner/7.jpg"),
          jumpUrl: "/web/ipSeries?id=19",
        },
        {
          imageUrl: require("@/assets/images/banner/1.jpg"),
          jumpUrl: "/web/ipSeries?id=13",
        },
        {
          imageUrl: require("@/assets/images/banner/2.jpg"),
          jumpUrl: "/web/ipSeries?id=12",
        },
        {
          imageUrl: require("@/assets/images/banner/4.jpg"),
          jumpUrl: "/web/ipSeries?id=14",
        },
      ],
      bannerDataRight: {
        top: {
          url: require("@/assets/images/banner/8.jpg"),
          link: "/web/marketing",
        },
        bottom: {
          url: require("@/assets/images/banner/9.jpg"),
          link: "/web/allGoods?cls_no=2403",
        },
      },
      num: 1,
      noticeData:[],
      noticeIconData:[],
      lang: "",
    };
  },
  created() {
    this.getHot(); //获取热门产品
    this.homeGoodsDataFun(); //获取家具用品
    this.get_HEALTH_AND_BEAUTY(); //获取健康美容
    this.get_ACCESSORIES(); //获取健康美容
    this.get_STATIONERY(); //获取文具
    this.get_SEASONAL_PRODUCTS_fun(); //季节性产品
    this.get_TOY_AND_DOLL(); //获取包包
    this.get_DIGITAL_ACCESSORIES(); //获取数码产品
    this.getBannerData();
    this.getAllNotice();
    this.getAllIconNotice()
    this.getLang();

  },
  methods: {
    // 获取当前的语言环境
    getLang() {
            // if (this.$i18n.locale == "zh-CN") {
            // 	this.lang = this.$i18n.locale
            // } else {
            // }
            this.lang = this.$i18n.locale;
        },
    //跳转到列表页
    JumpToAllNotice(){
      let {
					href
				} = this.$router.resolve({
					path: "/web/noticeList",
					query: {
					},
				});
				window.open(href)
    },
    //跳转到详情页
    JumpToNoticeDetail(row) {
				let {
					href
				} = this.$router.resolve({
					path: "/web/noticeDetail",
					query: {
						id: row.id,
					},
				});
				window.open(href)
			},
     // 获取公告栏
     getAllNotice(){
      notice.get().then((res) => {
        this.noticeData=res.data.data?res.data.data:[];
      })
    },
    // 获取有预览图公告数据
    getAllIconNotice(){
      notice.getIcon().then((res) => {
        this.noticeIconData=res.data.data?res.data.data:[];
      })
    },
    //定义轮播图跳转方法
    linkTo(url) {
      // const activeIndex=this.$refs.carousel.activeIndex;
      // this.$router.push(this.bannerData[activeIndex].link);
      this.$router.push(url)
    },
    //图片跳转
    imgTolink(url) {
      this.$router.push(url);
    },
    //获取数码产品
    get_DIGITAL_ACCESSORIES() {
      GetTypeGoods(29, 5).then((res) => {
        this.DIGITAL_ACCESSORIES_Data=this.filterGoodsList(res.data.data);
      });
    },
    //获取公仔
    get_TOY_AND_DOLL() {
      GetTypeGoods(28, 5).then((res) => {
        this.TOY_AND_DOLL_Data=this.filterGoodsList(res.data.data);
      });
    },
    //获取包包
    get_BACKPACK_JEWELRY_fun() {
      GetTypeGoods(26, 5).then((res) => {
        this.BACKPACK_JEWELRY_Data=this.filterGoodsList(res.data.data);
      });
    },
    //季节性产品
    get_SEASONAL_PRODUCTS_fun() {
      GetTypeGoods(25, 5).then((res) => {
        this.SEASONAL_PRODUCTS_Data=this.filterGoodsList(res.data.data);
      });
    },
    //获取文具
    get_STATIONERY() {
      GetTypeGoods(24, 5).then((res) => {
        this.STATIONERY_Data=this.filterGoodsList(res.data.data);
      });
    },
    //饰品
    get_ACCESSORIES() {
      GetTypeGoods(23, 5).then((res) => {
        this.ACCESSORIES_Data=this.filterGoodsList(res.data.data);
      });
    },
    //跳转到商品详情页
    goToUrl(goodsCode) {
      let { href }=this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: goodsCode,
        },
      });
      window.open(href);
    },
    //健康美容
    get_HEALTH_AND_BEAUTY() {
      GetTypeGoods(22, 5).then((res) => {
        this.HEALTH_AND_BEAUTY=this.filterGoodsList(res.data.data);
      });
    },
    //获取家具用品
    homeGoodsDataFun() {
      GetTypeGoods(21, 5).then((res) => {
        this.homeGoodsData=this.filterGoodsList(res.data.data);
      });
    },
    //获取热门产品
    getHot() {
      GoodsGetHot(11).then((res) => {
        let hotData=this.filterGoodsList(res.data.data);
        let listData=[];
        for (let i=0; i < hotData.length; i++) {
          if (i == 0) {
            this.hotGoodsData.firtData=hotData[i];
          } else {
            listData.push(hotData[i]);
          }
        }
        this.hotGoodsData.listData=listData;
      });
    },
    //过滤语言切换
    filterGoodsList(goodsData) {
      let resData=goodsData;
      for (let i=0; i < resData.length; i++) {
        //语言判断
        if (this.$i18n.locale == "zh-CN") {
          resData[i].item_en=resData[i].item_name;
        } else {
          resData[i].item_en=resData[i].item_en;
        }
      }
      return resData;
    },
    getBannerData() {
      banner.getBanner(0).then((res) => {
        res.data.data.length > 0 ? this.bannerData=res.data.data : "";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.noticeStyle{
  text-align: left;
  color:#E6A23C;
  margin-top: 10px;
  // margin-bottom: 10px;
  padding-bottom: 0 !important;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  cursor: pointer;
}

.index {
  width: 100%;
  background-color: #f5f8fa;
  margin: 0;
  // overflow-x: hidden;

  &_first {
    text-align: center;
  }

  &_second {
    width: 1200px;
    height: 500px;
    background-color: #ffffff;
    margin: 24px auto 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &_left {
      width: 300px;
      //height: 460px;
      background-image: url("~@/assets/images/Popular_pg.png");
    }

    &_title {
      width: 300px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_bottom {
      width: 260px;
      //height: 140px;
      margin: 20px auto;

      &_content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &_right {
      width: 900px;
      //height: 460px;
      //margin-top: -2px;
      display: grid;
      grid-template-columns: 179px 179px 179px 179px 179px;
      //grid-template-rows: 230px 230px 230px 230px 230px;

      &_content {
        width: 179px;
        height: 250px;
        border: 1px solid #f5f8fa;
      }

      &_bottom {
        width: 150px;
        height: 62px;
        margin: 0 auto;
      }
    }
  }

  &_third {
    width: 1200px;
    height: 722px;
    background-color: #ffffff;
    margin: 24px auto 0;

    &_header {
      height: 60px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_bottom {
      width: 1175px;
      height: 340px;
      margin: 22px 0 0 10px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &_content {
        width: 235px;
        height: 340px;
        border: 1px solid #f5f8fa;
        margin-top: -5px;
      }

      &_mark {
        width: 220px;
        height: 110px;
        margin: 0 auto;
      }
    }
  }
}
</style>
